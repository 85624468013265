<template>
    <div class="resume-page">
      <div class="outer-container ">
        <div class="header-section">
          <h2 class="header-title">个人简介</h2>
          <hr class="divider-line"/>
        </div>
        <!-- <el-avatar :src="require('@/assets/logo.png')" class="profile-avatar"></el-avatar> -->
        <section class="info-section">
          <h3>基本信息</h3>
            <ul>
                <li>姓名：姜书亭</li>
                <li>学历：本科</li>
                <li>工作经验：7年</li>
                <li>求职岗位：针对Unity的TA岗或客户端岗</li>
            </ul>
            <ul>
                <div class="link-section">
                    <div @click="goTo('https://github.com/bbsuuo')">
                        <img src="@/assets/images/icons/github.png" alt="GitHub" class="link-icon">
                        <span>Github</span>
                    </div>
                    <div @click="goTo('https://xblstudio.com/')">
                        <img src="@/assets/images/icons/jstweb.png" alt="个人站" class="link-icon">
                        <span>个人站</span>
                    </div>
                </div>
            </ul>
            <ul>
                <h3>联系方式</h3>
                <li>手机：+86 18670068235</li>
                <li>电子邮件：shuting.technology@foxmail.com</li>
            </ul>
        </section>


        <section class="education-section">
          <h3>教育背景</h3>
          <p>湖南人文科技学院 - 软件工程</p>
          <p>时间：2013年-2017年</p>
        </section>

 

        <section class="skills-section">
 
          <h3>编程技能</h3>
          <ul>
              <li>C#, C++, Python, JS, Lua, GLSL, HLSL</li>
          </ul>
          <div class="chart-container">
            <canvas id="skillsChart"></canvas>
          </div>

          <h3>优势与专长</h3>
          <ul>
              <li>拥有7年Unity相关开发经验</li>
              <li>拥有4年图形学相关开发经验</li>
              <li>拥有完整的项目开发到上线的多款游戏项目经验</li>
              <li>拥有开发游戏服务器端的经验</li>
              <li>拥有劫持hook等反编译的安全相关经验</li>
              <li>拥有git,svn等多种软件的项目管理经验</li>
              <li>拥有项目性能分析,性能优化经验</li>
              <li>拥有多年读源码经验,可以接手任何代码</li>
              <li>拥有资产热更,代码热更,配表框架,Jenkins部署以及git等项目部署经验</li>
              <li>拥有服务器开发经验,熟悉protobuf,json等多种序列化数据传输方式</li>
              <li>熟悉3D建模UV绑骨动画制作和流程规范</li>     
              <li>可以开发unity,blender,sd等各种软件的插件</li>
              <li>可以开发各种网站及后台管理系统</li>
              <li>可独立实现各种框架,渲染等不同模块的任务</li>
              <li>擅长使用RenderDoc等工具分析项目</li>
              <li>擅长搭建和使用各种AI工具,包括不限于ChatGpt,StableDiffusion,So-Vits-Svc等</li>
          </ul>

          <h3>软件技能</h3>
          <div class="software-icons">
              <el-image
              v-for="(path, index) in iconPaths"
              :key="index"
              :src="path"
              class="software-icon"
              ></el-image>
          </div>
 
      </section>
      </div>
    </div>
</template>
  
<script> 
    import { Chart } from 'chart.js/auto';
    export default {
        name: 'ResumePage',
        mounted() {
            this.initSkillsChart();
        },

        data() {
        return {
            softwareIcons: [
                'unity', 'blender', 'spine', 'substance-designer', 
                'substance-painter', 'houdini', 'photoshop', 'unreal', 'zbrush'
            ]
            };
        },
        computed: {
            iconPaths() {
                return this.softwareIcons.map(icon => require(`@/assets/images/icons/${icon}.png`));
            }
        },
        methods: {
            goTo(link) {
                window.open(link, '_blank');
            },
            initSkillsChart() {
            const ctx = document.getElementById('skillsChart').getContext('2d');
                new Chart(ctx, {
                    type: 'radar',
                    data: {
                        labels: ['C#', 'C++', 'Python', 'JS', 'Lua', 'GLSL', 'HLSL'],
                        datasets: [{
                            label: '编程技能',
                            data: [100, 70, 65, 75, 65, 80, 90],
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            borderColor: 'rgba(75, 192, 192, 1)',
                            borderWidth: 1
                        }]
                    },
                    options : {
                        scales: {
                            r: {
                                angleLines: {
                                    display: false
                                },
                                suggestedMin: 0,
                                suggestedMax: 100
                            }
                        }
                    }


                });
            }
        }
}
</script>
  
  
<style scoped>
.outer-container {
    width: 80% ;
    max-width: 1080px;
    margin: 0 auto;
    border-top: 1px solid #ccc; /* 这里设置底部边框为none */
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: none; /* 这里设置底部边框为none */
    padding: 20px;
}
@media print {
  .outer-container {
    width: 95%;
  }
}


.chart-container {
    width: 260px;
    height: 260px;
    position: relative;   
}

.profile-avatar {
    width: 64px;
    height: 64px;
    margin-bottom: 10px; 
 }
.header-section {
    margin-bottom: 20px;
}

.header-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #525252;
}

.divider-line {
    width: 100%;
    border: 0.5px solid #ccc;
    margin-bottom: 20px; /* 添加间距在横线和下一个区块之间 */
}

.info-section, .education-section, .skills-section, .hobbies-section {
    margin-bottom: 30px; /* 为每个区块添加间距 */
}

h3 {
    font-size: 20px;
    margin-bottom: 15px;
    color: #333;
}

ul {
    list-style-type: none;
    padding-left: 0;
}

li {
    margin-bottom: 10px;
    font-size: 16px;
    color: #666;
}

p {
    font-size: 16px;
    color: #666;
    margin-bottom: 10px;
}

h4 {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #444;
}

.software-icons {
    display: flex;
    gap: 10px; /* 图标之间的间距 */
}

.software-icon {
    width: 50px; 
    height: 50px;
    border-radius: 10%; 
    object-fit: cover; 
    display: block; /* 新增这一行 */
}

/* 媒体查询，确保在移动端图标大小不变 */
@media (max-width: 768px) {
    .software-icon {
        width: 24px;
        height: 24px;
    }
}

.link-section {
    display: flex;
    gap: 20px;
    margin-top: 10px;
}

.link-section > div {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.link-icon {
    width: 24px;
    height: 24px;
    object-fit: cover; /* 保证图标不被拉伸或压缩 */
    margin-right: 5px;  
}

</style>
  