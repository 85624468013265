<template>
    <div class="work-experience-page">
        <div class="outer-container">
            <div class="header-section">
                <h2 class="header-title">工作经历</h2>
                <hr class="divider-line"/>
            </div>

            <section v-for="job in jobs" :key="job.company" class="job-section">
                <h3>
                    {{ job.company }} 
                    <span class="time">({{ job.startDate }} - {{ job.endDate }})</span>
                </h3>
                <p><strong>职位：</strong>{{ job.position }}</p>
                <p><strong>主要工作：</strong>{{ job.responsibilities.join(', ') }}</p>
            </section>
        </div>
    </div>
</template>


<script>
import workData from '@/assets/datas/resume_work_data.json';

export default {
    name: 'WorkExperiencePage',
    data() {
        return {
            jobs: workData.workExperiences
        };
    }
}
</script>

<style scoped>
.outer-container {
    width: 80%;
    max-width: 1080px;
    margin: 0 auto;
    padding: 20px;
    border-top: none;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: none;
}
@media print {
  .outer-container {
    width: 95%;
  }
}


.header-section {
    margin-bottom: 20px;
}

.header-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #525252;
}

.divider-line {
    width: 100%;
    border: 0.5px solid #ccc;
    margin-bottom: 20px;
}

.job-section {
    margin-bottom: 30px;
}

h3 {
    font-size: 20px;
    margin-bottom: 15px;
    color: #333;
}

p {
    font-size: 16px;
    color: #666;
    margin-bottom: 10px;
}

.time {
    font-size: 12px;
    color: #999;
}
</style>
