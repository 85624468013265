<template>
  <div id="app" class="flex-container">
    <BaseHeader class="base-header"/>
    <router-view class="flex-item"></router-view>
  </div>
</template>

<script>
import BaseHeader from './views/layouts/BaseHeader.vue'
 

export default {
  name: 'App',
  components: {
    BaseHeader,
  } 
}
</script>

<style>
.flex-container {
  display: flex;
  flex-direction: column; /* 垂直排列子元素 */
  height: 100vh; /* 使 #app 填充整个视口高度 */
}

.base-header {
  height: 60px;
}
@media print {
  .base-header {
    display: none;
  }
}


.flex-item {
  flex: 1; /* 使 router-view 占据剩余空间 */
}
</style>

 