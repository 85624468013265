<template>
  <div class="project-page">
    <div class="outer-container">
      <div class="header-section">
        <h2 class="header-title">项目经历</h2>
        <hr class="divider-line" />
      </div>

      <div class="project-container" v-for="project in projects" :key="project.name">
        <div class="project-image-container">
          <img :src="getImagePath(project.image)" class="project-image" />
        </div>
        <div class="project-info">
          <h3 class="project-name">{{ project.name }}</h3>
          <p class="project-content" v-html="formatContent(project.content)"></p>
          <div class="project-meta">
            <time class="time">{{ project.startDate }}</time>
            <el-button type="text" class="button" @click="goTo(project.link)">跳转页面</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import projectData from '@/assets/datas/resume_project_data.json';

export default {
  name: 'ProjectPage',
  data() {
    return {
      projects: projectData,
    };
  },
  methods: {
    goTo(link) {
      window.open(link, '_blank');
    },
    getImagePath(path) {
      return require('@/assets/images/projectcard/' + path);
    },  
    formatContent(content) {
        return content.replace(/\n/g, '<br>');
    },
  } 
};
</script>

<style scoped>
.header-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #525252;
}

.outer-container {
    width: 80% ;
    max-width: 1080px;
    margin: 0 auto;
    border-top: none; /* 这里设置底部边框为none */
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: none; /* 这里设置底部边框为none */
    padding: 20px;
}
@media print {
  .outer-container {
    width: 95%;
  }
}

.project-container {
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee; /* 为每个项目添加底部边框 */
  padding-bottom: 20px; /* 为底部边框添加一些内边距 */
}

.project-image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.project-image {
  max-width: 300px; /* 调整图片最大宽度 */
  height: auto;
}

.project-info {
  flex: 3;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-name {
  font-size: 24px;
  margin-bottom: 10px;
  color: #585858;
}

.project-content {
  font-size: 18px; /* 维持原有字体样式 */
  color: #5f5f5f; /* 维持原有颜色 */
  overflow-y: auto;  
  max-height: 150px; /* 或其他你想要的高度 */
}

.project-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.time {
  font-size: 12px; /* 维持原有字体样式 */
  color: #999; /* 维持原有颜色 */
}

.button {
  font-size: 12px; /* 维持原有字体样式 */
  color: #999; /* 维持原有颜色 */
  min-height: auto;
  padding: 0; /* 保留按钮的内边距和高度设置 */
}
</style>
